<template>
  <section class="w-screen h-screen absolute overflow-hidden">
    <header class="opacity-100">
      <div class="nav">
        <div class="title">
          Navigation
        </div>
        <div class="toggle"
         @click="openMenu"
             :class="{ clicked: open }"
        >
          <div class="bar"></div>
        </div>

        <nav

             :class="{ open: open }"
        >
          <div class="showcase">
            <div class="showcase-container">
              <ul class="showcase-menu">
                <li>Hi!</li>
                <li>Hover over the list items or click them!</li>
                <li><img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/124740/ice-final-white.svg" alt=""></li>
                <li class="overlay"
                    style="background-image:url(https://images.unsplash.com/photo-1537949721120-e8f21f6698e6?ixlib=rb-0.3.5&s=96527c5eff0b3bd24946f77f4dc5a789&auto=format&fit=crop&w=634&q=80);">
                  Lorem ipsum
                </li>
                <li style="background-image:url(https://d2kbkpwf98wmwo.cloudfront.net/images/menu/motion.png);"></li>
                <li>ABC</li>
                <li style="background-image:url(https://images.unsplash.com/photo-1537914675540-ec9f82fbd752?ixlib=rb-0.3.5&s=7d427165e9b4e7aa8d80b19c9d0b3128&auto=format&fit=crop&w=1316&q=80);"></li>
                <li style="background-image:url(https://d2kbkpwf98wmwo.cloudfront.net/images/menu/color.png);"></li>
              </ul>
            </div>
          </div>

          <div class="menu">
            <ul class="main-menu"

            >
              <li class="active"


                  :class="{ hover: hover }"


              ><a >Introduction</a></li>
              <li><a >The System</a></li>
              <li><a >Logo</a></li>
              <li><a >Composition</a></li>
              <li><a >Motion</a></li>
              <li><a >Typography</a></li>
              <li><a >Photography</a></li>
              <li><a >Color</a></li>
            </ul>
          </div>
        </nav>

      </div>
    </header>

    <div class="content">

    </div>
  </section>

</template>

<script>

import jQuery from "jquery";

import gsap from 'gsap'
const $ = jQuery;
window.$ = $;

$(document).ready(function() {
  navNumbers();
  backToDefault();

  // show hovered li stuff
  $('.main-menu').on('mouseover', 'li', function() {
    showTarget($(this));
  });

  // show default .active li stuff
  $('.main-menu').on('mouseleave', backToDefault);

  // change active list item
  $('.main-menu').on('click', 'li', function() {
    changeActive($(this));
  });

  // for showcase only
  var tl = gsap.timeline()
   var   body = $('body')
   var  header = $('header')
   var  content = $('.content p')


  tl.to(body, 1, {
    overflow: 'hidden',
    delay: .5
  });

  tl.to(header, .25, {
    opacity: 1,
    delay: .5
  });

  tl.to(content, .25, {
    opacity: 1
  }, '-=.25');
});



// give the list items numbers
function navNumbers() {

  var i = 0;
  $('.main-menu li').each(function() {
    $(this).attr('data-target', i);
    i++;
  });
}


// show the hovered list item stuff
function showTarget(e) {
  $('.main-menu li').removeClass('hover');

  var target = $(e).attr('data-target');
  var showcaseHeight = $('.showcase-menu').outerHeight();

  showcaseHeight = (showcaseHeight * target) * -1;

  $('.showcase-menu').css({
    top: showcaseHeight
  });

  $(e).addClass('hover');
}

// show the list item stuff of .active
function backToDefault() {
  $('.main-menu li').removeClass('hover');

  var activeItem = $('.main-menu li.active');
  var target = activeItem.attr('data-target');
  var showcaseHeight = $('.showcase-menu').outerHeight();

  showcaseHeight = (showcaseHeight * target) * -1;

  $('.showcase-menu').css({
    top: showcaseHeight
  });

  activeItem.addClass('hover');
}


// change active list item
function changeActive(e) {
  $('.main-menu li').removeClass('active');
  $(e).addClass('active');
}



export default {
  name: "HamburgerMenuNr4",


  data() {

    return {

      open: false,
      hover: false

    }


  },


  methods: {

    openMenu() {

      this.open = !this.open


    },

    show() {


      this.showTarget($(this));


    },



    showTarget(e){

      $('.main-menu li').removeClass('hover');

      var target = $(e).attr('data-target');
      var showcaseHeight = $('.showcase-menu').outerHeight();

      showcaseHeight = (showcaseHeight * target) * -1;

      $('.showcase-menu').css({
        top: showcaseHeight
      });

      $(e).addClass('hover');



    },
/*
    function showTarget(e) {
  $('.main-menu li').removeClass('hover');

  var target = $(e).attr('data-target');
  var showcaseHeight = $('.showcase-menu').outerHeight();

  showcaseHeight = (showcaseHeight * target) * -1;

  $('.showcase-menu').css({
    top: showcaseHeight
  });

  $(e).addClass('hover');
}*/


  }

}
</script>


<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css?family=Noto+Sans:400,700');

$main: #EF3340;



section {
  overflow: hidden;
  display: flex;
  transition: padding .5s cubic-bezier(.25, .46, .45, .94);
  padding: 0 80px 80px;
}

a {
  text-decoration: none;
  color: $main;
}

header {
  z-index: 5;
  opacity: 1;
  transition: opacity .5s cubic-bezier(.25, .46, .45, .94);

  .nav {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 80px;

    .title {
      line-height: 80px;
      padding: 0 120px;
      font-size: 24px;
      color: #fff;
      font-weight: 900;
    }

    .toggle {
      position: absolute;
      margin: auto;
      width: 46px;
      height: 46px;
      right: 120px;
      top: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 10;
      cursor: pointer;

      .bar {
        position: relative;
        width: 40px;
        height: 2px;
        background: #fff;

        &:before, &:after {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          background: inherit;
          left: 0;
          transform-origin: center center;
          transition: transform .25s ease, top .25s ease, bottom .25s ease;
        }

        &:before {
          top: -10px;
        }

        &:after {
          bottom: -10px;
        }
      }

      &.clicked {
        .bar {
          background: transparent;

          &:before, &:after {
            background: #000;
          }

          &:before {
            transform: rotate(45deg);
            top: 0;
          }

          &:after {
            transform: rotate(-45deg);
            bottom: 0;
          }
        }
      }
    }

    nav {
      position: fixed;
      left: 0;
      top: 0;
      width: 100vw;
      height: 100vh;
      display: flex;
      flex-direction: row;
      opacity: 0;
      z-index: 5;
      transition: opacity .5s ease;

      .showcase {
        background: #000;
        flex: 1;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;

        .showcase-container {
          width: 50%;
          position: relative;
          overflow: hidden;

          &:before {
            content: '';
            display: block;
            padding-top: 100%;
          }
        }

        .showcase-menu {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          overflow-y: visible;
          list-style-type: none;
          transition: top .25s ease;


          li {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 40px;
            text-transform: uppercase;
            text-align: center;
            overflow: hidden;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            position: relative;
            z-index: 1;

            &.overlay {
              &:before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                background: rgba(0, 0, 0, .6);
                z-index: -1;
              }
            }
          }
        }
      }

      .menu {
        background: #fff;
        flex: 1;

        ul {
          width: 100%;
          padding: 80px 48px 24px 48px;
          list-style-type: none;
          max-height: 100%;
          overflow-y: auto;

          &::-webkit-scrollbar {
            width: 6px;
            height: 10px;

            &-button {
              width: 0px;
              height: 0px;
            }

            &-thumb {
              background: rgba(0, 0, 0, .4);
              border: 0px none #ffffff;
              border-radius: 3px;

              &:hover {
                background: #000;
              }

              &:active {
                background: #000;
              }
            }

            &-track {
              background: rgba(0, 0, 0, .1);
              border: 0px none #ffffff;
              border-radius: 0;

              &:hover {
                background: rgba(0, 0, 0, .1);
              }

              &:active {
                background: rgba(0, 0, 0, .1);
              }
            }

            &-corner {
              background: transparent;
            }
          }

          li {
            display: flex;
            flex-direction: row;
            align-items: center;

            .number {
              display: inline-block;
              padding: 0 0 0 80px;
              overflow: hidden;

              span {
                display: block;
                font-size: 48px;
                font-weight: 400;
                transition: transform .25s ease;
                transform: translateY(100%);
              }
            }

            a {
              display: inline-block;
              font-size: 48px;
              color: #000;
              line-height: 1;
              padding: 24px 0 24px 80px;
              transition: transform .25s ease;
            }

            &:hover {
              a {
                transform: translateX(20px);
              }
            }

            &.hover {
              .number {
                span {
                  transform: translateY(0);
                }
              }
            }
          }
        }
      }

      &.hidden {
        display: none;
      }

      &.open {
        opacity: 1;

        .showcase {
          animation: slideInLeft .5s cubic-bezier(.3, 0, .3, 1) forwards;
        }

        .menu {
          animation: slideInRight .5s cubic-bezier(.3, 0, .3, 1) forwards;
        }
      }
    }
  }
}


@keyframes slideInRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideInLeft {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

.content {
  position: relative;
  flex: 1;
  padding: 80px 40px 40px;
  background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/124740/2018-09-28%2009_21_48-https___d2kbkpwf98wmwo.cloudfront.net_videos_hero-lg.mp4.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  p {
    color: #fff;
    opacity: 0;
    transition: opacity .5s cubic-bezier(.25, .46, .45, .94);

    a {
      color: #fff;
      box-shadow: 0 1px 0 0 $main;
    }
  }
}


</style>